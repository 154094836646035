import { t } from "i18n:astro";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { Button } from "../layout/Button/Button";
import { ButtonStyleVariant } from "../layout/Button/types";

import { AnimatePresence, motion } from "framer-motion";
import "./style.scss";

const storageIdentifier = "cookies-agreement";

const valueCheck = (val: string | undefined): boolean => {
  if (val !== undefined) {
    const standard = val.toLowerCase().trim();
    switch (standard) {
      case "true":
        return true;
      case "1":
        return true;
      default:
        return false;
    }
  }
  return false;
};

const CookiesDialog = () => {
  const [open, setOpen] = useState(false);

  // if cookies terms ware not agreed then open dialog
  useEffect(() => {
    const storageValue = Cookies.get(storageIdentifier);
    const parsed = valueCheck(storageValue);
    if (!parsed) {
      setOpen(true);
    }
  }, []);

  return (
    <AnimatePresence mode="wait">
      {open && (
        <motion.div
          id="cookies-dialog"
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 50 }}
          transition={{ duration: 0.3 }}
        >
          <div id="cookies-card">
            <p>{t("common:cookies.description")}</p>
            <a href="/legal" target="_self" aria-label="Legal information link">
              <span>{t("common:cookies.more")}</span>
            </a>
            <Button
              styleVariant={ButtonStyleVariant.STANDARD}
              text={t("cookies.accept")}
              onClick={() => {
                Cookies.set(storageIdentifier, "true");
                setOpen(false);
              }}
            />
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default CookiesDialog;
